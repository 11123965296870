import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope} = JshModule('teaser/teaser-links');

const appendUrlParameterToLink = (item) => (e) => {
    if (item.dataset.cpUrlQueryParams) {
        const separator = item.href.includes('?') ? '&' : '?';
        item.href = item.href + separator + item.dataset.cpUrlQueryParams;
        item.dataset.cpUrlQueryParams = '';
    }
};

const allTeaserLinksWithParams = moduleScope.querySelectorAll('a[data-cp-url-query-params]');

allTeaserLinksWithParams.forEach((item) => {
    ['click', 'auxclick', 'focus'].forEach((eventname) => {
        item.addEventListener(eventname, appendUrlParameterToLink(item));
    });
});
